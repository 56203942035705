<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterpriseService' }">基础服务</el-breadcrumb-item>
        <el-breadcrumb-item>工商社保</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container container_bg">
      <div class="box">
        <div class="one_title">企业是否面临以下问题</div>
        <div class="img_box">
          <img
            src="../../assets/img/serviceSolution/serviceSolution.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="one_title">
          <span>缴纳方式自由</span> 可选社保自有户托管缴纳或大户托管
        </div>
        <div class="two_title">有效解决企业社保问题，可节省30%左右相关成本</div>
        <div class="account_box">
          <div class="account_list">
            <div class="account_item">
              <div class="item_head">
                <div class="account_head">
                  <div class="head_title">自有社保账户托管</div>
                  <div class="account_type">企业自有户操作</div>
                </div>
                <div class="account_content">
                  企业将社保账户托管到平台，由平台完成
                  企业社保账户管理维护及合规材料开具、员工增减员、工伤理赔/生育报销等多项服务
                </div>
              </div>
              <div class="item_content">
                <div class="service_list">
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">协助企业社保代开户</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">共计44项社保托管相关服务及理赔协办</div>
                  </div>

                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">送政策咨询和劳务法咨询</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">配套实时政策大全及计算器等工具</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">送新动中国年度巡讲门票4张</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">支持多种付费模式</div>
                  </div>
                </div>
                <div class="item_btn">咨询管家</div>
              </div>
            </div>
            <div class="account_item">
              <div class="item_head">
                <div class="account_head">
                  <div class="head_title">社保账户托管(本地/异地)</div>
                  <div class="account_type">托管户操作</div>
                </div>
                <div class="account_content">
                  企业托管社保账户托管事务到平台
                  由平台帮助企业实现本地社保账户托管、托管外地社保/外地托管社保
                </div>
              </div>
              <div class="item_content">
                <div class="service_list">
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">企业用工社保账户托管办理</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">共计44项社保账户托管相关业务</div>
                  </div>

                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">送政策咨询和劳务法咨询</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">配套实时政策大全及计算器等工具</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">送新动中国年度巡讲门票2张</div>
                  </div>
                  <div class="service_item">
                    <div class="radio">
                      <img
                        src="../../assets/img/serviceSolution/radio.png"
                        alt=""
                      />
                    </div>
                    <div class="label">支持多种付费模式</div>
                  </div>
                </div>
                <div class="item_btn">咨询管家</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="one_title">服务内容</div>
        <div class="service_box">
          <div class="standard">
            <div class="standard_title">18项 标准化服务(社保+公积金)</div>
            <div class="standard_list">
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">入职手续办理</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">生活补贴申报</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">工伤申报</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保合规证明</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">用工报备、退工报备</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">打印参保证明</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保转移、信息变更</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保增、减、补</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保享受</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">劳务用工备案</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">年度基数调整</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">生育、医疗报销、异地安置</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保重复缴纳退费</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">失业登记卡开具</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保卡、存折领取</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保账户合并、转移、信息变更</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">失业保险申领的办理、待遇享受</div>
              </div>
              <div class="standard_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">
                  非因公死亡社保个人账户结算、丧葬费及抚恤金
                </div>
              </div>
            </div>
          </div>
          <div class="added">
            <div class="added_title">26项 增值服务</div>
            <div class="added_list">
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">残疾人保障金申报和减免招待费</div>
              </div>

              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">就业协议签订(应届生三方协议)</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">退休办理</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">养老手册核定、办理失业证</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">稳岗补贴申报</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">外国人签订</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">档案业务</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">公积金办理托收</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">配合年检</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">外国人就业证、工作证</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">社保死亡、办理托收</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">稽核、监察案件代理</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">销户处理</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">编写客户员工手册</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">工资册备案</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">可办理城市的居住证积分-续办</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">大病保险申报</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">落户(毕业生)、符合当地落户条件</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">可办理城市的居住证积分-新办</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">企业聘用外国人居住证</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">外国人居住证</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">成立工会、缴纳工会费</div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">
                  社保年终清算、社保清算、医疗清算、死清算
                </div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">
                  落户(人才落户)、符合当地落户条件
                </div>
              </div>
              <div class="added_item">
                <div class="left_icon">
                  <img
                    src="../../assets/img/serviceSolution/left_icon.png"
                    alt=""
                  />
                </div>
                <div class="right_content">
                  编制员工手册并及时更新、更新频率不低一季度一次
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="one_title">服务流程与保障</div>
        <div class="two_title">标准化服务流程保障社保无忧</div>
        <div class="process_img_box">
          <img src="../../assets/img/serviceSolution/process.png" alt="" />
        </div>

        <div class="process_btn">立即咨询</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
  margin-bottom: 30px;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_title span {
  display: block;
  margin: 20px 0;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.one_title span {
  color: #1c69fe;
}

.two_title {
  font-size: 18px;
  color: #949494;
  text-align: center;
  margin-top: 10px;
}

.img_box {
  width: 70%;
  /* height: 450px; */
  margin: 0 auto;
  margin-top: 80px;
}

.img_box img {
  display: block;
  width: 100%;
  height: 100%;
}

.account_box {
  margin-top: 70px;
}

.account_list {
  display: flex;
  justify-content: space-between;
}

.account_item {
  width: 586px;
  height: 378px;
  background: #f7f7ff;
  box-shadow: 0px 0px 21px 0px rgba(29, 88, 255, 0.25);
  border-radius: 10px;
}

.item_head {
  width: 586px;
  height: 148px;
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  padding: 40px 0 20px 28px;
}

.account_head {
  display: flex;
}

.head_title {
  font-size: 26px;
  font-weight: bold;
  color: #000000;
}

.account_type {
  width: 131px;
  height: 34px;
  border: 2px solid #055afe;
  border-radius: 17px;
  font-size: 15px;
  font-weight: bold;
  color: #055afe;
  text-align: center;
  line-height: 30px;
  margin-left: 20px;
}

.account_content {
  font-size: 13px;
  color: #949494;
  margin-top: 20px;
}

.item_content {
  padding: 20px 33px;
}

.service_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service_item {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 17px;
}

.radio {
  width: 17px;
  height: 17px;
}

.radio img {
  display: block;
  width: 100%;
  height: 100%;
}

.label {
  font-size: 13px;
  color: #000000;
  margin-left: 10px;
}

.item_btn {
  width: 157px;
  height: 34px;
  background: #055afe;
  border-radius: 17px;
  margin: 0 auto;
  font-size: 17px;
  font-weight: bold;
  color: #fffefe;
  text-align: center;
  line-height: 34px;
  margin-top: 40px;
  cursor: pointer;
}

.service_box {
  width: 100%;
  height: 549px;
  background: #f7f7ff;
  border: 4px solid #055afe;
  margin-top: 60px;
  display: flex;
}

.standard {
  width: 491px;
  background: #ffffff;
}

.standard_title {
  width: 100%;
  text-align: center;
  line-height: 70px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  height: 73px;
  background: #055afe;
}

.added {
  width: 708px;
  height: 73px;
  background: rgba(5, 90, 254, 0.3);
}

.added_title {
  width: 100%;
  text-align: center;
  line-height: 70px;
  font-size: 26px;
  font-weight: bold;
  color: #055afe;
}

.left_icon {
  width: 9px;
  height: 9px;
}

.left_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.right_content {
  font-size: 14px;
  color: #000000;
  margin-left: 10px;
}

.standard_list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px 0 36px;
}

.standard_item {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.standard_item:last-child {
  width: 100%;
}

.added_list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px 0 36px;
}

.added_item {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.added_item:last-child {
  width: 100%;
}

.process_img_box {
  width: 100%;
}

.process_btn {
  width: 212px;
  height: 47px;
  background: #055afe;
  border-radius: 23px;
  font-size: 24px;
  font-weight: bold;
  color: #fffefe;
  text-align: center;
  line-height: 47px;
  margin: 40px auto;
  cursor: pointer;
}
</style>
